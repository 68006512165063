<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logos/carry-grn-yllw.svg" />
    <h1>Próximamente lanzamiento</h1>
    <p>
      ¿Curiosidad? Eso nos encanta, porque lo que estamos cocinando detras de
      escena es especial para las operaciones legales.
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
