<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link :to="{ name: 'login' }">Login</router-link> |
    <router-link :to="{ name: 'recoverPassword' }">Rec. Pass</router-link> |
    <router-link :to="{ name: 'signup' }">Create</router-link> |
  </nav>
  <router-view />
  <loading-modal v-if="isLoading"></loading-modal>
</template>

<script>
import { defineAsyncComponent, computed } from "vue";
import { useLoadingContentStore } from "@/stores/loadingContent";

export default {
  components: {
    LoadingModal: defineAsyncComponent(() =>
      import("@/modals/LoadingModal.vue")
    ),
  },

  setup() {
    const loadingStore = useLoadingContentStore();
    const isLoading = computed(() => loadingStore.isLoading);

    return { isLoading };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@import "./styles/main.scss";
</style>
