import { defineStore } from "pinia";

export const useLoadingContentStore = defineStore("loadingContent", {
  state: () => ({
    isLoading: false,
    messageLoading: "",
  }),

  actions: {
    setLoadingStatus(value) {
      this.isLoading = value;
    },
    setMessageLoading(value) {
      this.messageLoading = value;
    },
  },
});
