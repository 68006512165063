export default [
  {
    path: "/pago/:cobroId/:creatorId/:datePay",
    name: "viewPayment",
    component: () =>
      import(
        /* webpackChunkName: "viewPayment" */ "../views/ViewSharedPayment.vue"
      ),
    props: true,
  },
];
