import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import loginSignupRoutes from "@/modules/authentication/router/loginSignupRoutes";
import eventRoutes from "@/modules/events/routes/eventRoutes";
import documentsRoutes from "@/modules/documents/routes/documentsRoutes";
import paymentsRoutes from "@/modules/payments/routes/paymentsRoutes";
import esignsRoutes from "@/modules/esigns/routes/esignsRoutes";
import helpRoutes from "@/modules/help/routes/helpRoutes";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  ...loginSignupRoutes,
  ...eventRoutes,
  ...documentsRoutes,
  ...paymentsRoutes,
  ...esignsRoutes,
  ...helpRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
