export default [
  {
    path: "/firmar/:esignId/:signatorieId",
    name: "signDocument",
    component: () =>
      import(
        /* webpackChunkName: "signDocument" */ "../views/signDocument.vue"
      ),
    props: true,
  },
  {
    path: "/descargar/firmaelectronica/:signId/:creatorId/:type",
    name: "downloadEsignDocument",
    component: () =>
      import(
        /* webpackChunkName: "downloadEsignDocument" */ "../views/DownloadEsignDocument.vue"
      ),
  },
];
