export default [
  {
    path: "/ver/documentos-compartidos/:documentId/:userId",
    name: "sharedDocument",
    component: () =>
      import(
        /* webpackChunkName: "viewSharedDocument" */ "../views/ViewSharedDocument.vue"
      ),
    props: true,
  },
];
